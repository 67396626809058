/* global graphql */

import React from 'react';
import RainbowCTA from '../components/RainbowCTA';
import CalloutUnderline from '../components/Callout/CalloutUnderline';
import TemplateWrapper from '../components/TemplateWrapper';
import People from '../components/PeopleSection';

const PeoplePage = props =>

  (<TemplateWrapper location={props.location}>
    <main className="PeoplePage">
      <section className="Section PeoplePage-callout-one">
        <div className="container-fluid">
          <div className="wrapper">
            <div className="row">
              <div className="col-xs-14 col-md-12 col-md-offset-1">
                <CalloutUnderline underlineColor="orange" lineOne="Got the Magic in me." />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="Section PeoplePage-intro">
        <div className="container-fluid">
          <div className="wrapper">
            <div className="row">
              <div className="col-xs-14 col-md-12 col-md-offset-1">
                <p className="Caption-C3 uppercase PeoplePage-intro-title">Background</p>
              </div>
              <div className="col-xs-14 col-md-5 col-md-offset-1 PeoplePage-intro-info">
                <hr className="PeoplePage-intro-hr" />
                <h2 className="Heading Heading-h2 light PeoplePage-intro-info-title">Magic Matters.</h2>
                <p className="BodyText-B1 PeoplePage-intro-info-p">Before Magic, there were fences separating devices. Networks layered ontop of one another, congesting spectrum. Devices struggled to find secure access. Roaming agreements take years to finalize. </p>
                <p className="BodyText-B1 PeoplePage-intro-info-p">Magic is the Internet as it should be: an open, fluid, decentralized, trustless, and secure network that is fault and bad-actor tolerant, rewarding those who add value to the network. </p>
              </div>
              <div className="col-xs-14 col-md-5 col-md-offset-1 PeoplePage-intro-info">
                <h2 className="Heading Heading-h2 light PeoplePage-intro-info-title">The Team.</h2>
                <p className="BodyText-B1 PeoplePage-intro-info-p">Magic is an open-source project being built by a team of networking experts with decades of experience in cellular, WiFi, and cutting edge connectivity technology.</p>
                <p className="BodyText-B1 PeoplePage-intro-info-p">We’re a team of networking experts with decades of experience in cellular, WiFi, and cutting edge connectivity technology.</p>
                <p className="BodyText-B1 PeoplePage-intro-info-p">Interested in joining our team? <a className="Btn BtnText BtnText-orange light font-regular" href="mailto:hello@magic.co">Shoot us an email</a>.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <RainbowCTA />
    </main>
  </TemplateWrapper>);

export default PeoplePage;







